<template>
  <div class="">
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong>
          Posts
        </strong>
        <div class="d-flex">
          <hwa-button
            v-if="$can('view-tags', 'hwa')"
            variant="info"
            label="Tags"
            icon="GridIcon"
            class="mr-2"
            @onClick="openTagsModal()"
          />
          <hwa-button
            v-if="$can('view-blog-categories', 'hwa')"
            variant="info"
            label="Categories"
            icon="GridIcon"
            class="mr-2"
            @onClick="openCategoryModal()"
          />
          <hwa-button
            v-if="$can('create-blog-posts', 'hwa')"
            variant="primary"
            label="Create Post"
            icon="PlusIcon"
            @onClick="openPostModal()"
          />
        </div>

      </div>
      <b-table
        small
        :fields="fields"
        :items="posts"
        responsive="sm"
        selectable
        select-mode="single"
        @row-clicked="handleRowClick"
      >
        <!-- <template #cell(body)="data">
          <div v-html="data.item.body" />
        </template> -->
        <template #cell(views)="data">
          <div class="px-1">
            <b-badge variant="light-success">
              {{ data.item.views_count }}
            </b-badge>
          </div>
        </template>
        <template #cell(image)="data">
          <b-avatar :src="getImage(data.item.image)" />
        </template>
      </b-table>
    </b-card>
    <validation-observer
      ref="postForm"
      v-slot="{ invalid }"
    >
      <hwa-modal
        :title="`Blog Post`"
        :show="postModalOpened"
        size="lg"
        hide-footer
        :is_data_change="is_blog_change"
        @onClose="closePostModal()"
      >
        <validation-provider
          #default="{ errors }"
          name="Title"
          rules="required"
        >
          <hwa-input
            v-model="post.title"
            name="title"
            label="Title"
            placeholder="Enter title"
            :state="errors.length > 0 ? false : null"
          />
        </validation-provider>
        <hwa-select
          v-model="post.category_id"
          label-name="text"
          value-name="value"
          :options="options"
          label="Category"
          class="mb-2"
        />
        <hwa-multi-select
          v-model="post.tags"
          :options="tags"
          label-name="title"
          value-name="id"
          label="Tags"
        />
        <div class="row mt-2">
          <div
            class="col-md-8"
            style="display:flex;"
          >
            <hwa-file-button
              class="mr-1"
              label="Upload Image"
              @onFileUpload="handleFileUpload"
            />
            <div
              v-if="temImage"
              style="display:flex;justify-content:center;align-items:center;"
            >
              <b-avatar :src="temImage" />
            </div>
          </div>
        </div>
        <div class="mt-1">
          <validation-provider
            #default="{ errors }"
            name="Description"
            rules="required"
          >
            <hwa-text-area
              v-model="post.description"
              label="Description"
              :state="errors.length > 0 ? false : null"
            />
          </validation-provider>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <label for="">Content</label>
          </div>
          <div class="col-12">
            <quill-editor v-model="post.body" />
          </div>
        </div>
        <div
          class="mt-3"
          style="display:flex;justify-content:space-between;"
        >

          <hwa-button
            variant="secondary"
            icon="XIcon"
            class="mr-1"
            :class="is_blog_change ? 'active-secondary' : ''"
            label="Cancel"
            @onClick="closePostModal()"
          />
          <hwa-button
            v-if="updatePostMode && $can('delete-blog-posts', 'hwa')"
            variant="danger"
            icon="XIcon"
            class="mr-1"
            label="Delete"
            @onClick="deletePost()"
          />

          <hwa-button
            v-if="updatePostMode && $can('update-blog-posts', 'hwa')"
            variant="success"
            :label="'Update'"
            :disabled="invalid"
            :loading="postLoading"
            loading-text="Loading..."
            :class="is_blog_change === true ? 'active-success' : ''"
            @onClick="updatePost()"
          />
          <hwa-button
            v-if="!updatePostMode && $can('create-blog-posts', 'hwa')"
            variant="success"
            :label="'Create'"
            :disabled="invalid"
            :loading="postLoading"
            loading-text="Loading..."
            @onClick="createPost()"
          />
        </div>
      </hwa-modal>
    </validation-observer>

    <!-- Category Modal start -->
    <hwa-modal
      :title="`Blog Categories`"
      :show="categoryModalOpened"
      size="lg"
      hide-footer
      @onClose="closeCategoryModal()"
    >
      <div class="d-flex justify-content-between mb-2 mt-2">
        <hwa-button
          v-if="$can('create-blog-categories', 'hwa')"
          variant="primary"
          label="Create Categories"
          icon="PlusIcon"
          @onClick="openPostCategoryModal()"
        />
      </div>
      <b-table
        responsive="sm"
        :fields="c_fields"
        :items="categories"
        selectable
        @row-clicked="handleCategoryRowClick"
      >
        <template #cell(total_post)="data">
          {{ data.posts_count }}
        </template>
      </b-table>
    </hwa-modal>

    <!-- Category Modal End -->

    <!-- Tags Modal start -->
    <hwa-modal
      :title="`Tags`"
      :show="tagsModalOpened"
      size="lg"
      hide-footer
      @onClose="closeTagsModal()"
    >
      <div class="d-flex justify-content-between mb-2 mt-2">
        <hwa-button
          v-if="$can('create-tags', 'hwa')"
          variant="primary"
          label="Create Tag"
          icon="PlusIcon"
          @onClick="openTagModal()"
        />
      </div>
      <b-table
        responsive="sm"
        :fields="t_fields"
        :items="tags"
        selectable
        @row-clicked="handleTagRowClick"
      >
        <template #cell(total_post)="data">
          {{ data.posts_count }}
        </template>
      </b-table>
    </hwa-modal>

    <!-- Tags Modal End -->

    <!-- Tags Form Start -->
    <validation-observer
      ref="postForm"
      v-slot="{ invalid }"
    >
      <hwa-modal
        :title="`Tags`"
        :show="tagFormModalOpened"
        size="md"
        hide-footer
        @onClose="closeTagFormModal()"
      >
        <validation-provider
          #default="{ errors }"
          name="Title"
          rules="required"
        >
          <hwa-input
            v-model="tag.title"
            name="title"
            label="Title"
            placeholder="Enter title"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <div class="mt-3 d-flex align-items-center justify-content-between">
          <hwa-button
            v-if="updateTagMode && $can('delete-tags', 'hwa')"
            variant="danger"
            icon="XIcon"
            class="mr-1"
            label="Delete"
            @onClick="deleteTag()"
          />

          <hwa-button
            variant="secondary"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeTagFormModal()"
          />

          <hwa-button
            v-if="updateTagMode && $can('update-tags', 'hwa')"
            variant="success"
            :label="'Update'"
            :disabled="invalid"
            :loading="tagLoading"
            loading-text="Loading..."
            @onClick="updateTag()"
          />
          <hwa-button
            v-if="!updateTagMode && $can('create-tags', 'hwa')"
            variant="success"
            :label="'Create'"
            :disabled="invalid"
            :loading="tagLoading"
            loading-text="Loading..."
            @onClick="createTag()"
          />
        </div>
      </hwa-modal>
    </validation-observer>
    <!-- Tags Form End -->

    <!-- Category Form Start -->
    <validation-observer
      ref="postForm"
      v-slot="{ invalid }"
    >
      <hwa-modal
        :title="`Category`"
        :show="postCategoryModalOpened"
        size="md"
        hide-footer
        @onClose="closePostCategoryModal()"
      >
        <validation-provider
          #default="{ errors }"
          name="Title"
          rules="required"
        >
          <hwa-input
            v-model="category.title"
            name="title"
            label="Title"
            placeholder="Enter title"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <div class="mt-3 d-flex align-items-center justify-content-between">
          <hwa-button
            v-if="updateCategoryPostMode && $can('delete-blog-categories', 'hwa')"
            variant="danger"
            icon="XIcon"
            class="mr-1"
            label="Delete"
            @onClick="deleteCategory()"
          />

          <hwa-button
            variant="secondary"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closePostCategoryModal()"
          />

          <hwa-button
            v-if="updateCategoryPostMode && $can('update-blog-categories', 'hwa')"
            variant="success"
            :label="'Update'"
            :disabled="invalid"
            :loading="postLoading"
            loading-text="Loading..."
            @onClick="updateCategory()"
          />
          <hwa-button
            v-if="!updateCategoryPostMode && $can('create-blog-categories', 'hwa')"
            variant="success"
            :label="'Create'"
            :disabled="invalid"
            :loading="postLoading"
            loading-text="Loading..."
            @onClick="createCategoryPost()"
          />
        </div>
      </hwa-modal>
    </validation-observer>
    <!-- Category Form End -->
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {
  BTable, BCard, BBadge, BFormSelect, BFormGroup, BFormRadioGroup, BAvatar, BRow,
} from 'bootstrap-vue'
import HwaFileButton from '@/layouts/components/HwaFileButton.vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import HwaSelect from '@/layouts/components/HwaSelect.vue'
import HwaMultiSelect from '@/layouts/components/HwaMultiSelect.vue'
import showToast from '@/mixins/showToast'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaModal from '@/layouts/components/HwaModal.vue'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'

export default {
  components: {
    HwaModal,
    HwaInput,
    HwaButton,
    HwaMultiSelect,
    HwaSelect,
    BTable,
    BCard,
    BBadge,
    BFormSelect,
    ValidationObserver,
    BFormGroup,
    BFormRadioGroup,
    quillEditor,
    BAvatar,
    HwaFileButton,
    BRow,
    HwaTextArea,
    ValidationProvider,
  },
  mixins: [showToast],
  data() {
    return {
      fields: [{ key: 'title', label: 'Title', sortable: true }, 'description', 'image', { key: 'views', label: 'Views', sortable: true }],
      c_fields: [{ key: 'title', label: 'Title', sortable: true }, { label: 'Total Post', key: 'posts_count', sortable: true }],
      t_fields: [{ key: 'title', label: 'Title', sortable: true }, { label: 'Total Post', key: 'posts_count', sortable: true }],
      // options: [],
      tagsModalOpened: false,
      tagFormModalOpened: false,
      updateTagMode: false,
      tags: [],
      posts: [],
      categories: [],
      title: '',
      post_id: null,
      category_id: null,
      postLoading: false,
      updatePostMode: false,
      postModalOpened: false,
      categoryModalOpened: false,
      postCategoryModalOpened: false,
      updateCategoryPostMode: false,
      selectedPost: {},
      tagLoading: false,
      post: {
        id: null,
        title: '',
        body: '',
        category_id: null,
        description: '',
        image: null,
        tags: [],
      },
      category: {
        id: null,
        title: '',
        posts_count: 0,
      },
      tag: {
        id: null,
        title: '',
        posts_count: 0,
      },
      tmp_image: null,
      is_blog_change: false,
    }
  },
  computed: {
    options() {
      return this.categories.map(category => ({ value: category.id, text: category.title }))
    },
    temImage() {
      if (typeof this.post.image === 'string') {
        return this.post.image
      }
      return this.tmp_image
    },
  },
  watch: {
    post: {
      handler() {
        this.is_blog_change = true
      },
      deep: true,
    },
  },
  mounted() {
    this.getPosts()
    this.getCategories()
    this.getTags()
  },
  methods: {
    getImage(image) {
      return `${process.env.VUE_APP_STORAGE_PATH}/${image}`
    },
    handleFileUpload(file) {
      this.post.image = file.file
      this.tmp_image = file.dataUrl
    },
    closeTagsModal() {
      this.tagsModalOpened = false
    },
    openTagsModal() {
      this.tagsModalOpened = true
    },
    closeTagModal() {
      this.tagFormModalOpened = false
    },
    openTagModal() {
      this.tag = {
        id: null,
        title: '',
      }
      this.updateTagMode = false
      this.tagFormModalOpened = true
    },
    handleTagRowClick(row) {
      this.tag = { ...row }
      this.updateTagMode = true
      this.tagFormModalOpened = true
    },
    createTag() {
      this.tagLoading = true
      const form = new FormData()
      form.append('title', this.tag.title)
      this.$http.post('/api/tags', form)
        .then(res => {
          this.tagLoading = false
          const { data } = res
          data.posts_count = 0
          this.tags = [data, ...this.tags]
          this.showSuccess('Created Successfully')
          this.tag.title = ''
          this.closeTagModal()
        })
        .catch(err => {
          this.tagLoading = false
          this.showError('Failed to create tag')
        })
    },
    updateTag() {
      this.tagLoading = true
      const data = { title: this.tag.title }
      this.$http.put(`/api/tags/${this.tag.id}`, data)
        .then(res => {
          this.tagLoading = false
          this.tags = this.tags.map(c => {
            if (c.id === res.data.id) {
              return { ...res.data, posts_count: this.tag.posts_count }
            }
            return c
          })
          this.showSuccess('Updated Successfully')
          this.tagFormModalOpened = false
          this.updateTagMode = false
        })
        .catch(err => {
          this.tagLoading = false
          this.showError('Failed to update tag')
        })
    },
    getTags() {
      this.$http.get('/api/tags')
        .then(res => {
          this.tags = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve tags')
        })
    },
    deleteTag() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/api/tags/${this.tag.id}`)
            .then(res => {
              this.tags = this.tags.filter(p => p.id !== res.data?.id)
              this.tagFormModalOpened = false
              this.updateTagMode = false
            })
            .catch(err => {
              this.showError('Failed to delete tag')
            })
        }
      })
    },
    openPostModal() {
      this.post = {
        id: null,
        title: '',
        body: '',
        image: null,
        category_id: null,
      }
      this.tmp_image = null
      this.updatePostMode = false
      this.postModalOpened = true
    },
    openPostCategoryModal() {
      this.category = {
        id: null,
        title: '',
      }
      this.tmp_image = null
      this.updatePostCategoryMode = false
      this.postCategoryModalOpened = true
    },
    closePostModal() {
      if (this.is_blog_change) {
        this.$swal({
          title: 'Do you want to save changes?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save changes!',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.postModalOpened = false
            this.is_blog_change = false

            this.updatePost()
          } else if (result.dismiss === 'cancel') {
            this.postModalOpened = false
            this.is_blog_change = false
          }
        })
      } else {
        this.postModalOpened = false
        this.is_blog_change = false
      }
    },
    closePostCategoryModal() {
      this.postCategoryModalOpened = false
    },
    closeCategoryModal() {
      this.categoryModalOpened = false
    },
    openCategoryModal() {
      this.categoryModalOpened = true
    },
    createPost() {
      this.postLoading = true
      this.is_blog_change = false
      const form = new FormData()
      form.append('title', this.post.title)
      this.post.tags.forEach(tag => {
        form.append('tags[]', tag.id)
      })
      form.append('category_id', this.post.category_id)
      if (this.post.image) {
        form.append('image', this.post.image)
      }
      form.append('body', this.post.body)
      form.append('description', this.post.description)
      this.$http.post('/api/blog_posts', form)
        .then(res => {
          this.postLoading = false
          this.posts = [res.data, ...this.posts]
          this.showSuccess('Created Successfully')
          this.getCategories()
          this.closePostModal()
        })
        .catch(err => {
          this.postLoading = false
          this.showError('Failed to create posts')
        })
    },
    createCategoryPost() {
      this.postLoading = true
      const form = new FormData()
      form.append('title', this.category.title)
      this.$http.post('/api/categories', form)
        .then(res => {
          this.postLoading = false
          const { data } = res
          data.posts_count = 0
          this.categories = [data, ...this.categories]
          this.showSuccess('Created Successfully')
          this.category.title = ''
          this.closePostCategoryModal()
        })
        .catch(err => {
          this.postLoading = false
          this.showError('Failed to create category')
        })
    },

    updatePost() {
      this.postLoading = true
      const form = new FormData()
      form.append('title', this.post.title)
      this.post.tags.forEach(tag => {
        form.append('tags[]', tag.id)
      })
      form.append('category_id', this.post.category_id)
      if (typeof this.post.image !== 'string') {
        form.append('image', this.post.image)
      }
      form.append('body', this.post.body)
      form.append('description', this.post.description)
      this.$http.post(`/api/blog_posts/${this.post.id}`, form)
        .then(res => {
          this.postLoading = false
          this.posts = this.posts.map(c => {
            if (c.id === res.data.id) {
              return res.data
            }
            return c
          })
          this.showSuccess('Updated Successfully')
          this.postModalOpened = false
          this.is_blog_change = false
          // this.closePostModal()
        })
        .catch(err => {
          this.postLoading = false
          this.showError('Failed to update posts')
        })
    },
    updateCategory() {
      this.postLoading = true
      const data = { title: this.category.title }
      this.$http.put(`/api/categories/${this.category.id}`, data)
        .then(res => {
          this.postLoading = false
          this.categories = this.categories.map(c => {
            if (c.id === res.data.id) {
              return res.data
            }
            return c
          })
          this.showSuccess('Updated Successfully')
          this.postCategoryModalOpened = false
          this.updateCategoryPostMode = false
        })
        .catch(err => {
          this.postLoading = false
          this.showError('Failed to update category')
        })
    },

    handleRowClick(row) {
      this.post = { ...row, image: this.getImage(row?.image) }
      this.updatePostMode = true
      this.postModalOpened = true
      const dom = this
      setTimeout(() => {
        dom.is_blog_change = false
      }, 100)
    },
    handleCategoryRowClick(row) {
      this.category = row
      this.updateCategoryPostMode = true
      this.postCategoryModalOpened = true
    },
    getPosts() {
      this.$http.get('/api/blog_posts')
        .then(res => {
          this.posts = res.data?.data
        })
        .catch(err => {
          this.showError('Failed to retrieve posts')
        })
    },
    getCategories() {
      this.$http.get('/api/categories')
        .then(res => {
          this.categories = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve categories')
        })
    },

    deletePost() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/api/blog_posts/${this.post.id}`)
            .then(res => {
              this.categories = this.categories.map(c => {
                if (c.id === this.post.category_id) {
                  c.posts_count--
                  return c
                }
                return c
              })
              this.posts = this.posts.filter(p => p.id !== this.post.id)
              this.postModalOpened = false
              this.updatePostMode = false
            })
            .catch(err => {
              this.showError('Failed to delete post')
            })
        }
      })
    },
    deleteCategory() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/api/categories/${this.category.id}`)
            .then(res => {
              this.categories = this.categories.filter(p => p.id !== res.data?.id)
              this.postCategoryModalOpened = false
              this.updateCategoryPostMode = false
            })
            .catch(err => {
              this.showError('Failed to delete category')
            })
        }
      })
    },
  },
}
</script>
